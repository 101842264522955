// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-introduction-mdx": () => import("./../../../../docs/Introduction.mdx" /* webpackChunkName: "component---docs-introduction-mdx" */),
  "component---docs-tools-crashlytics-mdx": () => import("./../../../../docs/Tools-Crashlytics.mdx" /* webpackChunkName: "component---docs-tools-crashlytics-mdx" */),
  "component---docs-tools-mapbox-mdx": () => import("./../../../../docs/Tools-Mapbox.mdx" /* webpackChunkName: "component---docs-tools-mapbox-mdx" */),
  "component---docs-tools-natural-language-processing-mdx": () => import("./../../../../docs/Tools-NaturalLanguageProcessing.mdx" /* webpackChunkName: "component---docs-tools-natural-language-processing-mdx" */),
  "component---docs-tools-porcupine-mdx": () => import("./../../../../docs/Tools-Porcupine.mdx" /* webpackChunkName: "component---docs-tools-porcupine-mdx" */),
  "component---docs-tools-sentry-mdx": () => import("./../../../../docs/Tools-Sentry.mdx" /* webpackChunkName: "component---docs-tools-sentry-mdx" */),
  "component---docs-utilization-app-mdx": () => import("./../../../../docs/Utilization-App.mdx" /* webpackChunkName: "component---docs-utilization-app-mdx" */),
  "component---docs-utilization-bo-mdx": () => import("./../../../../docs/Utilization-BO.mdx" /* webpackChunkName: "component---docs-utilization-bo-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

